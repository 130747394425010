<template>
    <div class="PriceCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-form
            @submit.native.prevent
            :inline="true"
            :model="form"
            :rules="rules"
            ref="createForm"
            label-width="80px"
            size="small"
        >
            <el-card shadow="never" style="margin-top: 8px">
                <el-row style="margin-top: 16px">
                    <ef-dept-type-and-dept-select
                        ref="selectDept"
                        dept-label="价格参考机构"
                        @selectDeptType="setDeptType"
                        @selectDept="setDept"
                        @selectDeptMore="selectDeptMore"
                    />
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="商品名称/条码/助记码/自编码" v-model="search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-checkbox
                            v-if="hasPrivilege('biz.price.wholeSale.query')"
                            v-model="form.checkedWholeSalePrice"
                            label="修改批发价"
                            border
                        />
                        <el-checkbox
                            v-if="hasPrivilege('biz.price.retail.query')"
                            v-model="form.checkedRetailPrice"
                            label="修改零售价"
                            border
                        />
                        <el-checkbox
                            v-model="form.checkedFactoryPrice"
                            v-if="showFactoryPriceFlag && hasPrivilege('biz.price.factory.query')"
                            label="修改出厂价"
                            v-show="deptType === 2"
                            border
                        />
                    </el-col>
                </el-row>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px; text-align: left">
                <el-button type="primary" @click="queryAndAdd" size="small" :disabled="disableQueryAndAddButton"
                    >查询并添加
                </el-button>
                <el-button type="primary" @click="showPickUpGoods" size="small" :disabled="disableShowPickUpGoodsDialog"
                    >选择商品基本信息
                </el-button>
                <el-button type="primary" @click="handleSave(0)" size="small" :disabled="disableSaveButton"
                    >保存
                </el-button>

                <el-button
                    type="primary"
                    @click="openDialog('1')"
                    v-if="hasPrivilege('menu.goods.price.review')"
                    size="small"
                    :disabled="disableSaveButton"
                    >保存并审核
                </el-button>
            </el-card>
            <el-card shadow="never" style="margin-top: 8px" align="left">
                <el-table
                    border
                    stripe
                    style="width: 100%"
                    :data="form.detailParams"
                    size="mini"
                    :highlight-current-row="true"
                    max-height="450"
                >
                    <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                    <el-table-column label="商品名称" width="250">
                        <template slot-scope="scope">
                            {{ skuByGoodsCode(scope.row.goodsCode).name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="商品条码" width="220" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.goodsCode'"
                                :rules="rules.goodsCode"
                            >
                                <span>{{ scope.row.sku.bars.map((d) => d).join(' / ') }}</span>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="批发价"
                        width="200"
                        v-if="form.checkedWholeSalePrice === true && hasPrivilege('biz.price.wholeSale.query')"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showWholeSalePrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input v-model="scope.row.showWholeSalePrices" :precision="2" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="零售价"
                        width="200"
                        v-if="form.checkedRetailPrice === true && hasPrivilege('biz.price.retail.query')"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showRetailPrices'"
                                :rules="rules.showPrices"
                            >
                                <ef-price-input v-model="scope.row.showRetailPrices" :precision="2" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="出厂价"
                        v-if="
                            showFactoryPriceFlag && form.checkedFactoryPrice && hasPrivilege('biz.price.factory.query')
                        "
                        width="200"
                    >
                        <template slot-scope="scope">
                            <el-form-item
                                :prop="'detailParams.' + scope.$index + '.showFactoryPrices'"
                                :rules="rules.showFactoryPrices"
                            >
                                <ef-price-input v-model="scope.row.showFactoryPrices" :precision="3" />
                            </el-form-item>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="80" header-align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="danger" @click="handleDelete(scope.$index)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-card>
        </el-form>
        <pick-up-goods ref="pickupGoods" @queryGoods="setSelectedGoods" />
        <el-dialog title="保存并审核" :visible.sync="dialogVisible" width="30%">
            <el-form :model="form" :rules="rules" ref="form" label-width="100px">
                <el-form-item label="审核备注:" prop="auditMark">
                    <el-input type="textarea" :rows="2" v-model="form.auditMark" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="agree"> 同意</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import MoneyUtils from 'js/MoneyUtils';
import Util from 'js/Util';
import PickUpGoods from 'components/PickUpGoods';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelectMoreForChangePrice';
import StockBizCommon, { factoryPrice, PropertyConverter, retailPrice, wholeSalePrice } from 'js/StockBizCommon';
import EfPriceInput from 'components/EfPriceInput';
import { deptCodeValidateRule, goodsCodeValidateRule, pricesValidateRule } from 'js/validate/ValidateCommonRule';
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'PriceCreate',
    components: { PickUpGoods, EfDeptTypeAndDeptSelect, EfPriceInput },
    props: {
        deptLabel: {
            type: String,
            default: '机构名称',
        },
    },
    data() {
        return {
            //有初始机构
            haveInitDept: false,
            routerName: 'menu.goods.price.batchChangePrice',
            search: '',
            deptType: null,
            deptTypes: [],
            dialogVisible: false,
            form: {
                checkedFactoryPrice: false,
                checkedWholeSalePrice: true,
                checkedRetailPrice: true,
                deptCode: '',
                deptName: '',
                detailParams: [],
                changeDeptCode: [],
                auditMark: '',
            },
            goodsDetailMap: new Map(),
            rules: {
                deptCode: deptCodeValidateRule(),
                goodsCode: goodsCodeValidateRule(),
                showPrices: [
                    { type: 'number', required: true, min: 0.01, message: '必须大于0', trigger: 'blur' },
                    pricesValidateRule(2),
                ],
                showFactoryPrices: [
                    { type: 'number', required: true, min: 0.001, message: '必须大于0', trigger: 'blur' },
                    pricesValidateRule(3),
                ],
                showPlanCostPrices: [
                    { type: 'number', required: true, min: 0.0001, message: '必须大于0', trigger: 'blur' },
                    pricesValidateRule(4),
                ],
                auditMark: [{ required: true, message: '必须填写备注', trigger: 'change' }],
            },
        };
    },
    computed: {
        //禁用/启用按钮
        disableQueryAndAddButton() {
            return Util.isEmpty(this.form.deptCode) || Util.isEmpty(this.search);
        },
        //禁用/启用按钮
        disableShowPickUpGoodsDialog() {
            return Util.isEmpty(this.form.deptCode);
        },
        //禁用/启用按钮
        disableSaveButton() {
            return (
                Util.isEmpty(this.form.deptCode) ||
                this.form.detailParams == null ||
                this.form.detailParams.length === 0
            );
        },
        showFactoryPriceFlag() {
            return this.deptType === 2;
        },
    },
    watch: {
        'form.deptCode': function (newDeptCode, oldDeptCode) {
            if (!this.haveInitDept) {
                //清空商品列表数据
                this.form.detailParams = [];
            } else {
                this.haveInitDept = false;
            }
        },
        $route: {
            immediate: true,
            handler: function (to, from) {
                if (to.name === this.routerName) {
                    this.initGoodsSelectData(to.params);
                }
            },
        },
    },
    methods: {
        initGoodsSelectData(params) {
            this.$nextTick(() => {
                if (Object.keys(params).length > 0) {
                    this.haveInitDept = true;
                    this.$refs.selectDept.initNowPage(params.goodsPageDeptCode, params.deptType);
                    this.setSelectedGoods(params.goodsPageSelect);
                } else {
                    this.haveInitDept = false;
                    this.$refs.selectDept.initNowPage('');
                }
            });
        },
        selectDeptMore(val) {
            this.form.changeDeptCode = val;
        },
        handleDelete(index) {
            this.form.detailParams.splice(index, 1);
        },
        setDept(deptCode, deptName, deptType) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
            this.deptType = deptType;
        },
        setDeptType(deptType) {
            this.deptType = deptType;
        },
        setSelectedGoods(selectedGoods) {
            StockBizCommon.addPickupGoodsToStockBizDetails(
                selectedGoods,
                this.goodsDetailMap,
                this.form.detailParams,
                this.detailParamPropertyArrForAdd()
            );
        },
        showPickUpGoods() {
            this.$refs.pickupGoods.show({ deptCode: this.form.deptCode }, this.form.deptName);
        },
        async queryAndAdd() {
            const rst = await this.$efApi.goodsApi.queryGoods({ deptCode: this.form.deptCode, search: this.search });
            this.setSelectedGoods(rst.data);
        },
        openDialog() {
            if (this.form.changeDeptCode.length > 0) {
                if (
                    this.form.checkedFactoryPrice === true ||
                    this.form.checkedWholeSalePrice === true ||
                    this.form.checkedRetailPrice === true
                ) {
                    this.$refs.createForm.validate((valid) => {
                        if (!valid) {
                            return false;
                        }
                        this.dialogVisible = true;
                    });
                } else {
                    this.$message.error('没有要修改的价格列');
                }
            } else {
                this.$message.error('请选择要变更的机构');
            }
        },
        agree() {
            if (this.form.auditMark === '') {
                this.$message.error('请输入审核备注');
            } else {
                this.handleSave(1);
            }
        },
        handleSave(val) {
            this.dialogVisible = false;
            if (this.form.changeDeptCode.length > 0) {
                if (
                    this.form.checkedFactoryPrice === true ||
                    this.form.checkedWholeSalePrice === true ||
                    this.form.checkedRetailPrice === true
                ) {
                    this.$refs.createForm.validate((valid) => {
                        if (!valid) {
                            return false;
                        }
                        const goodsParamsData = [];
                        const _this = this;
                        this.form.detailParams.forEach((item) => {
                            const goods = {};
                            goods.skuCode = item.sku.code;
                            if (!_this.form.checkedWholeSalePrice) {
                                goods.wholeSalePrice = null;
                            } else {
                                goods.wholeSalePrice = MoneyUtils.moneyToDb(item.showWholeSalePrices);
                            }
                            if (!_this.form.checkedFactoryPrice) {
                                goods.factoryPrice = null;
                            } else {
                                goods.factoryPrice = MoneyUtils.moneyToDb(item.showFactoryPrices);
                            }
                            if (!_this.form.checkedRetailPrice) {
                                goods.retailPrice = null;
                            } else {
                                goods.retailPrice = MoneyUtils.moneyToDb(item.showRetailPrices);
                            }
                            goodsParamsData.push(goods);
                        });
                        const param = {
                            deptCode: this.form.deptCode,
                            changeDeptCode: this.form.changeDeptCode,
                            goodsParams: goodsParamsData,
                            reviewStatus: val,
                            auditMark: this.form.auditMark,
                        };
                        UrlUtils.PostPriceBatchRemote(
                            this,
                            '/goods/price/batchChangePrice',
                            param,
                            { timeout: 3 * 60 * 1000 },
                            () => {
                                this.goBackAndReload();
                                this.$message.success('修改调价成功');
                            }
                        );
                    });
                } else {
                    this.$message.error('没有要修改的价格列');
                }
            } else {
                this.$message.error('请选择要变更的机构');
            }
        },
        goodsByCode(goodsCode) {
            return this.goodsDetailMap.get(goodsCode);
        },
        skuByGoodsCode(goodsCode) {
            return this.goodsByCode(goodsCode).sku;
        },
        detailParamPropertyArrForAdd() {
            const arr = [
                'goodsCode',
                'sku',
                new PropertyConverter(retailPrice, 'showRetailPrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
                new PropertyConverter(wholeSalePrice, 'showWholeSalePrices', (price) => {
                    return MoneyUtils.moneyToYuan(price);
                }),
                new PropertyConverter(factoryPrice, 'showFactoryPrices', (price) => {
                    if (price) {
                        return MoneyUtils.moneyToYuan(price);
                    } else {
                        return '';
                    }
                }),
            ];
            return arr;
        },
    },
};
</script>

<style scoped>
.BatchCreate .el-form-item {
    margin-bottom: 0;
}

.BatchCreate .el-table th.gutter {
    display: table-cell;
}
</style>
