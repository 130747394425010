<template>
    <span>
        <el-row>
            <el-col :span="6">
                <el-form-item label="机构类型">
                    <el-select v-model="deptType" value="deptType">
                        <el-option
                            v-for="item of deptTypes"
                            :label="item.name"
                            :value="item.deptType"
                            :key="item.deptType"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item :label="'价格参考机构'" label-width="120px">
                    <el-select v-model="deptCode" value="deptCode" filterable :disabled="canSelectDept">
                        <el-option
                            v-for="item of depts"
                            :label="item.name"
                            :value="item.deptCode"
                            :key="item.deptCode"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item :label="'价格变更机构'" prop="deptCodeMore" label-width="120px">
                    <el-select
                        v-model="deptCodeMore"
                        value="deptCode"
                        multiple
                        filterable
                        style="width: 400px"
                        :disabled="canSelectDept"
                    >
                        <el-option
                            v-for="item of depts"
                            :label="item.name"
                            :value="item.deptCode"
                            :key="item.deptCode"
                        />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </span>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import Util from 'js/Util';

export default {
    name: 'EfDeptTypeAndDeptSelect',
    props: {
        needDefaultDeptType: {
            type: Boolean,
            default: false,
        },
        deptLabel: {
            type: String,
            default: '机构名称',
        },
    },
    data() {
        return {
            deptType: '',
            deptCode: '',
            deptCodeMore: '',
            //机构类型列表
            deptTypes: [],
            depts: [],
            //设置为null，需要根据是否为null进行后续逻辑判断
            deptTypeWithDeptsArr: null,
            canSelectDept: false,
            haveInit: false,
        };
    },
    watch: {
        deptType: {
            handler(newDeptType, oldDeptType) {
                this.$emit('selectDeptType', newDeptType);
                this.filterDeptsByDeptTypeAndSetDefaultDeptCode();
            },
            immediate: true,
        },
        deptCode: {
            handler(newDeptCode, oldDeptCode) {
                //触发父组件监听的事件
                let dept = {};
                dept = this.depts.find((item) => {
                    return item.deptCode === newDeptCode;
                });
                const deptName = dept ? dept.name : '';
                const deptType = dept ? dept.type : null;
                this.$emit('selectDept', newDeptCode, deptName, deptType);
            },
        },
        deptCodeMore: {
            handler(newDeptCode, oldDeptCode) {
                //触发父组件监听的事件
                let dept = {};
                dept = this.depts.find((item) => {
                    return item.deptCode === newDeptCode;
                });
                const deptName = dept ? dept.name : '';
                const deptType = dept ? dept.type : null;
                this.$emit('selectDeptMore', newDeptCode, deptName, deptType);
            },
        },
    },
    created() {
        UrlUtils.DeptTrees(this, async (rst) => {
            //对机构列表查询结果进行处理，组装成组件需要的数据机构
            const deptTypeWithDeptsArr = [];
            for (const o of rst || []) {
                this.deptTypes.push(new DeptType(o.type, o.name));
                const deptsOfDeptType = new DeptsOfDeptType(o.type, []);
                for (const e of o.children) {
                    deptsOfDeptType.depts.push(new Dept(e.code, e.name, e.type, e.deptGroupCode));
                }
                deptTypeWithDeptsArr.push(deptsOfDeptType);
            }
            //最后阶段再赋值
            if (this.needDefaultDeptType) {
                let lastIdx = this.deptTypes.length - 1;
                if (lastIdx < 0) {
                    lastIdx = 0;
                }
                //最后一项设置为默认值
                this.deptType = this.deptTypes[lastIdx].deptType;
            } else {
                //设置默认空值
                this.deptTypes.unshift(new DeptType('', '请选择'));
                this.deptType = '';
            }
            this.deptTypeWithDeptsArr = deptTypeWithDeptsArr;
            await this.filterDeptsByDeptTypeAndSetDefaultDeptCode();
        });
    },
    methods: {
        filterDeptsByDeptTypeAndSetDefaultDeptCode() {
            this.depts = [];
            if (!this.haveInit) {
                this.deptCode = '';
            } else {
                this.haveInit = false;
            }
            this.deptCodeMore = '';
            for (const e of this.deptTypeWithDeptsArr || []) {
                if (Util.isEmpty(this.deptType)) {
                    this.depts.push(...e.depts);
                } else if (e.deptType === this.deptType) {
                    this.depts.push(...e.depts);
                }
            }
        },
        /**初始化设置机构的方法，通过$refs调用*/
        initNowPage(deptCode, deptType) {
            const timeSetInterval = setInterval(() => {
                if (this.depts.length > 0) {
                    this.haveInit = true;
                    this.deptType = deptType;
                    this.deptCode = deptCode;
                    clearInterval(timeSetInterval);
                }
            }, 100);
        },
    },
    updated() {
        this.canSelectDept = Util.isEmpty(this.deptType);
    },
};

class DeptType {
    constructor(deptType, name) {
        this.deptType = deptType;
        this.name = name;
    }
}

class Dept {
    constructor(deptCode, name, type, deptGroupCode) {
        this.deptCode = deptCode;
        this.name = name;
        this.type = type;
        this.deptGroupCode = deptGroupCode;
    }
}

class DeptsOfDeptType {
    constructor(deptType, depts) {
        this.deptType = deptType;
        this.depts = depts;
    }
}
</script>
